import { defineStore } from 'pinia'
import { ref } from 'vue'
import companyUserApi from '@/api/companyUser.api'
import type {
    CompanyUser,
    GetCompanyUsersResp,
    CompanyUsersApiParams,
    UsersSummaryInfo,
    GetCompanyUsersSummaryResp
} from '@/interfaces/CompanyUser'
import type { PageInfo } from '@/interfaces/PageInfo'

const defaultRoles = ['client', 'clientadmin', 'submitter', 'vendor']

export const useCompanyUserStore = defineStore('companyUsers', () => {
    const users = ref<CompanyUser[]>([])
    const pageInfo = ref<PageInfo>({ totalCount: 0, hasMore: false, currentPage: 1, size: 25, totalPages: 1 })
    const loading = ref<boolean>(true)

    // Add these new refs for filter state
    const searchQuery = ref('')
    const createdAtBefore = ref<string | undefined>(undefined)
    const createdAtAfter = ref<string | undefined>(undefined)
    const lastLoginBefore = ref<string | undefined>(undefined)
    const lastLoginAfter = ref<string | undefined>(undefined)
    const deactivatedAtBefore = ref<string | undefined>(undefined)
    const deactivatedAtAfter = ref<string | undefined>(undefined)
    const role = ref<string | undefined>(undefined)
    const active = ref<boolean | undefined>(undefined)
    const inactive = ref<boolean | undefined>(undefined)
    const usersSummary = ref<UsersSummaryInfo | undefined>(undefined)

    const fetchUsers = async (companyId: string, params: CompanyUsersApiParams) => {
        loading.value = true

        try {
            // If no role is provided, set it to the roles that we want to display on the team directory
            if (!params.role) {
                params.role = defaultRoles.join(',')
            }
            const response: GetCompanyUsersResp = await companyUserApi.fetchUsers(companyId, params)
            users.value = response.data
            pageInfo.value = response.pageInfo
        } catch (err: any) {
            console.error(err)
            throw new Error('Failed to fetch users')
        } finally {
            loading.value = false
        }
    }

    const patchUser = async (username: string, payload: { active: boolean }) => {
        loading.value = true
        try {
            await companyUserApi.patchUser(username, payload)
            const index = users.value.findIndex((user) => user.username === username)
            if (index !== -1) {
                users.value[index].active = payload.active
            }
        } catch (err: any) {
            console.error(err)
            throw new Error(`Failed to update user with username ${username}`)
        } finally {
            loading.value = false
        }
    }

    const fetchUsersSummary = async (companyId: string, params: CompanyUsersApiParams) => {
        try {
            const response: GetCompanyUsersSummaryResp = await companyUserApi.fetchUsersSummary(companyId, params)
            usersSummary.value = response.data
        } catch (err: any) {
            console.error(err)
            throw new Error('Failed to fetch users summary')
        }
    }

    // Add these new functions for managing filter state
    const updateSearchQuery = (query: string) => {
        searchQuery.value = query
    }

    const updateCreatedAtFilter = (filter: { createdAtBefore: string; createdAtAfter: string }) => {
        createdAtBefore.value = filter.createdAtBefore
        createdAtAfter.value = filter.createdAtAfter
    }

    const removeCreatedAtFilter = () => {
        createdAtBefore.value = undefined
        createdAtAfter.value = undefined
    }

    const updateLastLoginFilter = (filter: { lastLoginBefore: string; lastLoginAfter: string }) => {
        lastLoginBefore.value = filter.lastLoginBefore
        lastLoginAfter.value = filter.lastLoginAfter
    }

    const removeLastLoginFilter = () => {
        lastLoginBefore.value = undefined
        lastLoginAfter.value = undefined
    }

    const updateDeactivatedAtFilter = (filter: { deactivatedAtBefore: string; deactivatedAtAfter: string }) => {
        deactivatedAtBefore.value = filter.deactivatedAtBefore
        deactivatedAtAfter.value = filter.deactivatedAtAfter
    }

    const removeDeactivatedAtFilter = () => {
        deactivatedAtBefore.value = undefined
        deactivatedAtAfter.value = undefined
    }

    const updateUserRoleFilter = (filter: { role: string }) => {
        role.value = filter.role
    }

    const removeUserRoleFilter = () => {
        role.value = undefined
    }

    const updateUserStatusFilter = (filter: { active: boolean; inactive: boolean }) => {
        active.value = filter.active
        inactive.value = filter.inactive
    }

    const removeUserStatusFilter = () => {
        active.value = undefined
        inactive.value = undefined
    }

    function downloadFile(csvData: string, filename: string) {
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })            
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
    }

    async function exportUsers(companyId: string, params: CompanyUsersApiParams, filename: string = 'users_export') {
        try {
            const csvData = await companyUserApi.exportUsers(companyId, params)
            downloadFile(csvData, filename);
        } catch (err) {
            if (err instanceof Error && err.name === 'AbortError') {
                throw err; // Throw AbortError to be caught in the component
            }
            console.error('Export error:', err)
            throw new Error('Failed to export users')
        }
    }

    return {
        users,
        pageInfo,
        loading,
        fetchUsers,
        patchUser,
        fetchUsersSummary,
        searchQuery,
        createdAtBefore,
        createdAtAfter,
        lastLoginBefore,
        lastLoginAfter,
        deactivatedAtBefore,
        deactivatedAtAfter,
        role,
        active,
        inactive,
        usersSummary,
        updateSearchQuery,
        updateCreatedAtFilter,
        removeCreatedAtFilter,
        updateLastLoginFilter,
        removeLastLoginFilter,
        updateDeactivatedAtFilter,
        removeDeactivatedAtFilter,
        updateUserRoleFilter,
        removeUserRoleFilter,
        updateUserStatusFilter,
        removeUserStatusFilter,
        exportUsers
    }
})
