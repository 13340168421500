import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import FontAwesome, { type FontAwesomeOptions } from '@/plugins/fontawesome'
import PrimeVue from '@/plugins/primevue'
import Pinia from '@/plugins/pinia'
import SentryPlugin from '@/plugins/sentry'
import '../../assets/main.css'
import { appDivId } from './config'
import Dayjs from '@/plugins/dayjs'
import { regularIcons } from './icons'

const app = createApp(App)

const fontAwesomeOptions: FontAwesomeOptions = { regularIcons }

app.use(FontAwesome, fontAwesomeOptions)
app.use(PrimeVue)
app.use(Pinia)
app.use(SentryPlugin)
app.use(Dayjs)

if (router) {
    app.use(router)
}

app.mount(`#${appDivId}`)

console.log('LoginPage SPA mounted!')